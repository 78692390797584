<template>
  <svg class="bp-svg-sprite">
    <use
      :xlink:href="spriteId"
      class="bp-svg-sprite__sprite"
      :class="className"
    ></use>
  </svg>
</template>

<script>
export default {
  name: "SvgSprite",
  props: {
    svgSrc: {
      type: String,
      required: true
    },
    sprite: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    }
  },
  computed: {
    spriteId() {
      return this.sprite ? `${this.svgSrc}#${this.sprite}` : this.svgSrc
    }
  }
}
</script>

<style lang="scss">
.bp-svg-sprite {
  width: 100%;
  height: 100%;

  &__sprite {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>
